@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Inter', sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: 'transparent';
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: 'transparent';
} */

.table-scroll::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  
}

.table-scroll::-webkit-scrollbar-track {
  /* background: 'transparent'; */
  background:#edeff0;
  border-radius: 4px;
}

.table-scroll::-webkit-scrollbar {
  height: 8px;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555; */
}
